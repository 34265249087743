<template>
  <div class="pay-succeed">
    <div class="go-back">
      <i class="el-icon-arrow-left" @click="goHome"></i>
      <span @click="goHome">返回首页</span>
    </div>
    <div class="content">
      <div class="content-title">付款成功</div>
      <div class="content-info">
        <i class="el-icon-success"></i>
        <span class="content-info-suc">您已成功付款</span>
        <div class="content-info-text">恭喜，您的订单已经支付完成</div>
        <div class="content-info-btn">
          <!-- <div>查看订单</div> -->
          <div @click="goIndex">分配课程</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPublishBaseUrl, getBackstageUrl } from '@/utils/config.js'
export default {
  name: 'PaySucceed',
  data() {
    return {}
  },
  methods: {
    goHome() {
      // 为了防止回到首页，跳转到后台管理系统
      location.href = getPublishBaseUrl()
    },
    goIndex() {
      this.$confirm('即将跳转到后台管理系统，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // location.href = getBackstageUrl()
          window.open(getBackstageUrl() + '/study/companyCourse/purchase?isStore=true', '_blank')
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
.pay-succeed {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 60px;
  .go-back {
    display: flex;
    align-items: center;
    padding: 20px 0;
    i {
      color: #9e9e9e;
      cursor: pointer;
    }
    span {
      display: inline-block;
      font-size: 16px;
      color: #999999;
      margin-left: 11px;
      cursor: pointer;
    }
  }
  .content {
    border: 1px solid #e4e4e4;
    &-title {
      padding-left: 19px;
      height: 49px;
      background: #f3f4f5;
      line-height: 49px;
      font-size: 18px;
      color: #333333;
    }
    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 60px;
      padding-bottom: 80px;
      i {
        color: #00b42a;
        font-size: 68px;
      }
      .content-info-suc {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        display: inline-block;
        margin-top: 13px;
        margin-bottom: 10px;
      }
      .content-info-text {
        font-size: 14px;
        color: #666666;
      }
      .content-info-btn {
        display: flex;
        align-items: center;
        margin-top: 30px;
        div {
          width: 88px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          font-size: 14px;
          border-radius: 2px;
          cursor: pointer;
          &:first-child {
            color: #333;
            background: #f2f3f5;
          }
          &:last-child {
            color: #fff;
            background: #0077f9;
            margin-left: 16px;
          }
        }
      }
    }
  }
}
</style>
